import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../environment/environment";
import Moment from "react-moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pagination from "./Pagination/Pagination";
import swal from "sweetalert";


function Transactions() {
  let navigate = useNavigate();

  const { pages } = useParams()
  const [dataLoader, setDataLoader] = useState(false);
  const [pageNum, setPageNum] = useState(+pages);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [buttonLoader, setButtonLoader] = useState(false);

  // const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [fullList, setFullList] = useState([]);

  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const limitValue = 10;
  const [modalData, setModalData] = useState("");
  const [columnArr, setColumnArr] = useState([
    { name: "Key", value: "key", dir: 1 },
    { name: "Code", value: "code", dir: 1 },
    { name: "Value (Minute)", value: "val", dir: 1 },
  ]);
  useEffect(() => {
    getList(pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getList(val) {
    setSelectedPage(val);
    // setIsLoader(true);
    setDataLoader(true)
    // document.querySelector(".loader").classList.remove("d-none");
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };

    await axios
      .post(`${apiUrl}/admin/gettransaction`, obj)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            console.log("response.data", response?.data?.result[0]?.paginatedResults);
            console.log("response.data", response?.data?.result[0]?.totalCount[0].count);
            setNoOfRecords(response?.data?.result[0]?.totalCount[0].count)
            setList(response?.data?.result[0]?.paginatedResults);
            setFullList(response.data?.result2)
            // setIsLoader(false);
            setDataLoader(false)
          }, 500)
        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        // setIsLoader(false);
        setDataLoader(false)
        window.alert(error);
      });
  }


  const isViewTransaction = (element) => {
    console.log("element", element);
    setModalData(element);
    setShow(true);
  };

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    setColumnArr(columnArr);
    setIsSortedCol(col);
    getList(selectedPage);
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    getList(pageNum);
    // setIsLoader(false)
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value) {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      getList(pageNum);
      setPageNum(pageNum); // Reset page number when clearing the search
    }
  };

  const searchClick = async (searchData, pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };

      const response = await axios.post(`${apiUrl}/admin/searchtransactions`, { searchname: searchData, obj }, {})
      if (response.data.status === 1) {
        setList(response?.data?.result?.paginatedResults);
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0);
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }
    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // setList([]);
    // if (!searchData) {
    //   // setIsLoader(false)
    //   setDataLoader(false)
    //   getList(pageNum);
    //   setDataLoader(false)
    //   // setIsLoader(false)
    // } else {
    //   // document.querySelector('.loading').classList.remove('d-none');
    //   await axios
    //     .post(
    //       `${apiUrl}/admin/searchtransactions`,
    //       { searchname: searchData },
    //       {}
    //     )
    //     .then(function (response) {
    //       if (response.data.status === 1) {
    //         setList(response.data.result);
    //         setSearching(true);
    //       } else {
    //         window.alert(response.data.message);
    //       }
    //     })
    //     .catch(function (error) {
    //       // setIsLoader(false);
    //       setDataLoader(false)
    //       window.alert(error);
    //     });
    // }
  };

  const curPage = (pageNums) => {
    navigate(`/transactions/${pageNums}`);
    setPageNum(pageNums);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async () => {
        await searchClick(searchname, pageNums);
        setDataLoader(false);
      }, 500);
    } else {
      getList(pageNums);
    }
  };

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based
  const year = currentDate.getFullYear();

  // eslint-disable-next-line no-unused-vars
  const [popuploader, setPopuploader] = useState(false);


  return (
    <>
      {/* {isLoader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Transactions</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Transactions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <button className="search_btn" onClick={clearClick}>
                      <i className="fa fa-times"></i>
                    </button>
                  ) : (
                    <button className="search_btn" onClick={() => searchClick()}>
                      {" "}
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </div>
              </div>
              <div className="col-xl-2 col-md-3 col-sm-6 col-12 offset-md-3 offset-xl-6 text-right mb-2">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="add-btn export-btn"
                  table="client_transactions_export"
                  filename={`Transactions_${day}/${month}/${year}`}
                  sheet="tablexls"
                  buttonText="Export to excel"
                />
                {/* <button className="search_btn mt-0"><i className="fa fa-file-excel-o mr-2"></i>Export Report</button> */}
              </div>
            </div>

            {/*visible table in front-end*/}
            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive">
                    {dataLoader &&
                      <div id="loadermodal" className="loader"><div className="popuploader loginloader"></div></div>
                    }
                    {list?.length > 0 ? (<>
                      <table
                        id="client_transactions"
                        className="table table-bordered table-hover mb-0">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Member Name</th>
                            <th>Plan Name</th>
                            <th>Plan Date</th>
                            <th>Amount</th>
                            <th>Tax</th>
                            <th>Payment Method</th>
                            {/* <th className="text-center w130">Status</th> */}
                            <th className="text-center w130">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {list.map((ele, index) => {
                            return (
                              <tr>
                                <td>{index + 1 + (pageNum - 1) * limitValue}</td>
                                <td className="text-nowrap">
                                  {ele?.client_data?.firstname} {ele?.client_data?.lastname}
                                </td>
                                <td>{ele.plantype}</td>
                                <td className="text-nowrap">
                                  <Moment
                                    format="DD MMMM YYYY, hh:mm A"
                                    date={ele.date}
                                  />
                                </td>
                                <td>$ {ele.amount}</td>
                                <td className="text-nowrap">$ 35</td>
                                <td className="text-nowrap">Debit Card</td>

                                <td className="text-center">
                                  <button
                                    className="btn btn-eye mr-2"
                                    onClick={(e) => {
                                      isViewTransaction(ele);
                                    }}
                                  >
                                    <i className="ace-icon fa fa-eye"></i>
                                  </button>

                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                          <div className="text-center" colSpan="9">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Transactions Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                  </div>
                </div>
                {/* {searchname === "" ? (<> */}
                {list?.length > 0 && (
                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={pageNum}
                      totalCount={noOfRecords}
                      pageSize={limitValue}
                      onPageChange={(page) => curPage(page)}
                    />
                  </div>
                )}
                {/* </>) : (<></>)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hidden Table for Export */}
      <div style={{ display: "none" }}>
        <table id="client_transactions_export">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Member Name</th>
              <th>Plan Name</th>
              <th>Plan Date</th>
              <th>Amount</th>
              <th>Tax</th>
              <th>Payment Method</th>
            </tr>
          </thead>

          <tbody>
            {fullList.map((ele, index) => (
              <tr key={index}>
                <td>{index + 1 + (pageNum - 1) * limitValue}</td>
                <td className="text-nowrap">
                  {ele?.client_data?.firstname} {ele?.client_data?.lastname}
                </td>
                <td>{ele.plantype}</td>
                <td className="text-nowrap">
                  <Moment format="DD MMMM YYYY, hh:mm A" date={ele.date} />
                </td>
                <td>$ {ele.amount}</td>
                <td className="text-nowrap">$ 35</td>
                <td className="text-nowrap">Debit Card</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="loadermodal" className="loader d-none">
          <div className="popuploader loginloader"></div>
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Transactions</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 pb-3 booking-history transaction-m">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="row">
                  <label className="col-lg-4 col-md-5 col-12">
                    Client Name <span>:</span>
                  </label>
                  <div className="col-lg-8 col-md-7 col-12">
                    <span className="noti-content">{modalData?.client_data?.firstname}</span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-4 col-md-5 col-12">
                    Plan Name <span>:</span>
                  </label>
                  <div className="col-lg-8 col-md-7 col-12">
                    <span className="noti-content">{modalData.plantype}</span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-4 col-md-5 col-12">
                    Plan Date <span>:</span>
                  </label>
                  <div className="col-lg-8 col-md-7 col-12">
                    <span className="noti-content">
                      <Moment
                        format="DD MMMM YYYY, hh:mm A"
                        date={modalData.date}
                      />
                    </span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-4 col-md-5 col-12">
                    Amount <span>:</span>
                  </label>
                  <div className="col-lg-8 col-md-7 col-12">
                    <span className="noti-content">$ {modalData.amount}</span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-4 col-md-5 col-12">
                    Tax <span>:</span>
                  </label>
                  <div className="col-lg-8 col-md-7 col-12">
                    <span className="noti-content">$ 35</span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-4 col-md-5 col-12">
                    Payment Method <span>:</span>
                  </label>
                  <div className="col-lg-8 col-md-7 col-12">
                    <span className="noti-content">Debit Card</span>
                  </div>
                </div>
                {/* <div className="row">
                                    <label className="col-md-5 col-12">Status <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>Active</span>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Transactions;
