/* eslint-disable jsx-a11y/anchor-is-valid */
import $ from "jquery";
import { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import { apiUrl } from "../environment/environment";
import Swal from "sweetalert2";

function AdminLayout() {
  const navigate = useNavigate();
  const loginuserToken = sessionStorage.getItem("token");
  // const loginuserProfile=JSON.parse(sessionStorage.user).profile

  const firstname = JSON.parse(sessionStorage.user).firstname;
  const lastname = JSON.parse(sessionStorage.user).lastname;


  if (
    loginuserToken === "" ||
    loginuserToken === null ||
    loginuserToken === undefined
  ) {
    navigate("/");
  }

  const defaultImg = "/img/Small-no-img.png";

  const loginuserProfile = JSON.parse(sessionStorage.user).profile !== null && JSON.parse(sessionStorage.user).profile !== undefined &&
    JSON.parse(sessionStorage.user).profile !== ""
    ? apiUrl + JSON.parse(sessionStorage.user).profile
    : defaultImg


  useEffect(() => {
    $(".sidebar-dropdown").each(function (item) {
      $(this).removeClass("active");
      if ($(this).find("a").attr("href") === window.location.pathname) {
        $(this).addClass("active");
      }
    });
  });

  const LogOut = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: 'warning',
      title: "Are you sure you want to logout ?",
      showCancelButton: true,
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        // localStorage.clear();
        window.location.href = "/";
      }
    });
  };

  const showHidemenu = (e) => {
    document.querySelector("#mainmenu").classList.toggle("fliph");
  };

  const location = useLocation();
  let currentUrl = location.pathname.split('/');
  currentUrl = currentUrl[1];

  return (<>
    <div className="main">
      <button className="menubtn btn btn-sm btn-dark" onClick={showHidemenu}>
        <i className="fa fa-bars"></i>
      </button>
      <aside>
        <div className="sidebar left scroll-color" id="mainmenu">
          <div className="bg_white">
            <button className="close-icon btn" onClick={showHidemenu}>
              <i className="fa fa-bars"></i>
            </button>
          </div>
          <div className="user-profile">
            <div className="user-img">
              <img src={`${loginuserProfile}`} onError={(e) => {
                e.target.src = "/img/Small-no-img.png";
              }} alt="" />
              <h4 className="user-title">
                {firstname} {lastname}
              </h4>
            </div>

          </div>
          <ul className="list-sidebar bg-defoult">
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? "active" : "subtext")}
              >
                <i className="fa fa-th-large"></i>
                <span className="nav-label">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <a
                data-toggle="collapse"
                data-target="#UserManagement"
                className="collapsed subtext"
              >
                <i className="fa fa-user"></i>
                <span className="nav-label">User Management</span>
                <i className="fa fa-chevron-down d-arrow"></i>
              </a>
              <ul className="sub-menu collapse" id="UserManagement">
                <li>
                  <NavLink
                    to="/usermanagement/1"
                    className={(currentUrl === 'usermanagement' ? "active" : "")}
                  >
                    Staff/Sub-Admin
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/trainer/1"
                    className={(currentUrl === 'trainer' ? "active" : "")}
                  >
                    Trainer
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/member/1"
                    className={(currentUrl === 'member' ? "active" : "")}
                  >
                    Member
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/trainerbookinghistory"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Trainer Booking History
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/memberbookinghistory/1"
                    className={(currentUrl === 'memberbookinghistory' ? "active" : "")}
                  >
                    Member Booking History
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <a
                data-toggle="collapse"
                data-target="#workout"
                className="collapsed subtext"
              >
                <i className="fas fa-file-alt"></i>
                <span className="nav-label">Workout Form</span>
                <i class="fa fa-chevron-down d-arrow"></i>
              </a>
              <ul className="sub-menu collapse" id="workout">
                <li>
                  <NavLink
                    to="/workoutforms/1"
                    className={(currentUrl === 'workoutforms' ? "active" : "")}
                  >
                    Workout Form
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/workoutcategory/1"
                    className={(currentUrl === 'workoutcategory' ? "active" : "")}
                  >
                    Workout Category
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/basicMovements/1"
                    className={(currentUrl === 'basicMovements' ? "active" : "")}
                  >
                    Basic Movements
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <a
                data-toggle="collapse"
                data-target="#rating"
                className="collapsed subtext"
              >
                <i className="fa fa-star"></i>
                <span className="nav-label">Ratings</span>
                <i className="fa fa-chevron-down d-arrow"></i>
              </a>
              <ul className="sub-menu collapse" id="rating">
                <li>
                  <NavLink
                    to="/clientratings/1"
                    className={(currentUrl === 'clientratings' ? "active" : "")}
                  >
                    Client Ratings
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <a
                data-toggle="collapse"
                data-target="#master"
                className="collapsed subtext"
              >
                <i className="fa fa-database"></i>
                <span className="nav-label">Master</span>
                <i className="fa fa-chevron-down d-arrow"></i>
              </a>
              <ul className="sub-menu collapse" id="master">
                <li>
                  <NavLink
                    to="/managegoalstype/1"
                    className={(currentUrl === 'managegoalstype' ? "active" : "")}
                  >
                    Manage Goals Type
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/equipmentlisting/1"
                    className={(currentUrl === 'equipmentlisting' ? "active" : "")}
                  >
                    Equipment Listing
                  </NavLink>
                </li>
                {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/countrylisting" >Country Listing</NavLink></li> */}
              </ul>
            </li>
            <li>
              <a
                data-toggle="collapse"
                data-target="#subscription"
                className="collapsed subtext"
              >
                <i className="fas fa-money-bill"></i>
                <span className="nav-label">Subscriptions/Payment</span>
                <i className="fa fa-chevron-down d-arrow"></i>
              </a>
              <ul className="sub-menu collapse" id="subscription">
                <li>
                  <NavLink
                    to="/subscribers/1"
                    className={(currentUrl === 'subscribers' ? "active" : "")}
                  >
                    Subscribers/Plan
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/transactions/1"
                    className={(currentUrl === 'transactions' ? "active" : "")}
                  >
                    Transactions
                  </NavLink>
                </li>
                {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/paymentreport">Payment Report</NavLink></li> */}
              </ul>
            </li>
            <li>
              <a
                data-toggle="collapse"
                data-target="#others"
                className="collapsed subtext"
              >
                <i className="fa fa-th-list"></i>
                <span className="nav-label">Others</span>
                <i className="fa fa-chevron-down d-arrow"></i>
              </a>
              <ul className="sub-menu collapse" id="others">
                {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/paymentsetup">Payment Set-Up</NavLink></li> */}
                {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/profilemanagement">Profile Management</NavLink></li> */}
                <li>
                  <NavLink
                    to="/notifications/1"
                    className={(currentUrl === 'notifications' ? "active" : "")}
                  >
                    Notification
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings/1"
                    className={(currentUrl === 'settings' ? "active" : "")}
                  >
                    Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contactus/1"
                    className={(currentUrl === 'contactus' ? "active" : "")}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/newsletter/1"
                    className={(currentUrl === 'newsletter' ? "active" : "")}
                  >
                    News Letter
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* <li><button onClick={(e) => LogOut(e)}><i className="fa fa-sign-out"></i><span className="nav-label">LogOut</span></button></li> */}
          </ul>
        </div>
        <div className="main-content">
          <header className="header">
            <nav className="navbar navbar-toggleable-md navbar-light py-0">
              <div className="col-md-12 col-12 px-3">
                <div className="d-flex justify-content-between w-100">
                  <div className="admin-logo">
                    <img src="/img/common-health.png" alt="logo" />
                  </div>
                  <ul className="navbar-nav float-right">
                    <li className="nav-item dropdown user-menu">
                      <a
                        className="nav-link dropdown-toggle user-i"
                        href={() => false}
                        title="logout"
                        id="navbarDropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <img src={`${loginuserProfile}`} onError={(e) => {
                          e.target.src = "/img/Small-no-img.png";
                        }} alt="hphoto" />
                      </a>
                      <div
                        className="dropdown-menu adlogin"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <button className="logout">
                          <NavLink
                            to="profilemanagement"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            <i className="fa fa-user"></i>Profile
                          </NavLink>
                        </button>
                        <button className="logout" onClick={(e) => LogOut(e)}>
                          <i className="fa fa-power-off"></i>
                          <span className="nav-label">Logout</span>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
          <div className="main-content-inner">
            <Outlet />
          </div>
          <footer>
            <div className="footer-content">
              <p className="mb-0">&copy; 2024 - Common Health Management</p>
            </div>
          </footer>
        </div>
      </aside>
    </div>
  </>);
}

export default AdminLayout;
